import { InputFile, SelectedFile, Text } from '@workos-inc/component-library';
import { Colors } from '@workos-inc/ui-kit';
import { Fieldset } from 'components/fieldset';
import { Label } from 'components/label';
import { Error } from 'interfaces/errors';
import React from 'react';

export type FileInputChangeEvent = (event: {
  name: string;
  file: SelectedFile;
}) => void;

interface FileInputProps {
  accept?: string;
  error?: Error;
  id?: string;
  label?: string;
  name: string;
  onUpload: FileInputChangeEvent;
  value?: string;
}

export const FileField: React.FC<Readonly<FileInputProps>> = ({
  accept = '.cer, .cert, .crt, .key, .pem',
  error,
  id = '',
  label,
  name,
  onUpload,
  value,
}) => {
  const isError = error && error.value === value;
  return (
    <Fieldset>
      <Label color={isError ? Colors.Red : undefined}>{label}</Label>
      <InputFile
        accept={accept}
        className="break-all mt-2"
        id={id}
        label=""
        name={name}
        onChange={onUpload}
        onClear={() => onUpload({ name, file: { name, content: '' } })}
      />

      {isError && (
        <Text className=" text-red-600" size="small">
          {error?.message}
        </Text>
      )}
    </Fieldset>
  );
};
