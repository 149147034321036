import { Text } from '@workos-inc/component-library';
import { ConnectionAttributeMapping } from 'components/attribute-mapping';
import { Card } from 'components/card';
import { Confirm } from 'components/confirm';
import { FileField, TextField } from 'components/fields';
import { Form } from 'components/form';
import { Img } from 'components/image';
import { CopyInput } from 'components/input';
import { Article, Title } from 'components/typography';
import { ConnectionStepProps } from 'interfaces/step-props';
import React from 'react';

export const RipplingCreateApplication: React.FC<
  Readonly<ConnectionStepProps>
> = ({ onNextStep }) => (
  <Article>
    <Title className="mb-4">Step 1: Create Rippling SAML Application</Title>

    <Img
      height={900}
      priority={true}
      src="/sso/rippling/v1/rippling-1.png"
      width={1200}
    />

    <Text>
      Log in to Rippling as an Adminstrator and select "IT Management" then
      "Custom App" from the left-side navigation bar.
    </Text>

    <Img
      height={600}
      priority={true}
      src="/sso/rippling/v1/rippling-2.png"
      width={1000}
    />

    <Text>
      Select "Create New App" to begin creating a new SAML application.
    </Text>

    <Img
      priority
      height={1000}
      src="/sso/rippling/v1/rippling-3.png"
      width={800}
    />

    <Text>
      Give the app a descriptive name. You can select a category if relevant,
      but this field is not required. You will however, need to upload a logo
      file. Make sure to check the box for "Supports SAML", then click "Create
      App".
    </Text>

    <Img
      priority
      height={500}
      src="/sso/rippling/v1/rippling-4.png"
      width={800}
    />

    <Text>
      Select the option confirming that you are the Application Admin. Rippling
      will display a new page with information we will use in the next step.
    </Text>

    <Confirm
      label="I've configured the application and confirmed that I am the Application Admin."
      onClick={onNextStep}
    />
  </Article>
);

export const RipplingInputIdpDetails: React.FC<
  Readonly<ConnectionStepProps>
> = ({
  connection,
  errors,
  isLoading,
  onFileInput,
  onInputChange,
  onNextStep,
  validationErrors,
}) => (
  <Article>
    <Title className="mb-4">Step 2: Input the Rippling IdP Details</Title>

    <Text>
      Rippling will now provide the SSO set up instructions. Step 1 will include
      the SSO URL and X509 Certificate.
    </Text>

    <Img
      height={500}
      priority={true}
      src="/sso/rippling/v1/rippling-5.png"
      width={550}
    />

    <Text>
      Rippling provides the X509 certificate as a text blob. This will need to
      be copy and pasted into a text file and saved with a file extension type
      shown in the admin portal and then uploaded.
    </Text>

    <Card>
      <Form
        disabled={
          !connection?.saml_idp_url ||
          !connection?.saml_x509_certs ||
          !connection?.saml_entity_id ||
          validationErrors?.saml_idp_url?.value === connection?.saml_idp_url
        }
        isLoading={isLoading}
        isUpdate={
          !!(
            errors?.saml_idp_url ||
            errors?.saml_entity_id ||
            errors?.saml_x509_certs
          )
        }
        onSubmit={onNextStep}
      >
        <TextField
          autoFocus={true}
          error={errors?.saml_idp_url || validationErrors?.saml_idp_url}
          label="1. Single Sign On URL"
          name="saml_idp_url"
          onChange={onInputChange}
          placeholder="https://www.rippling.com/api/platform/sso/sp-initiated/12345678d0324dc3669b2dd5"
          value={connection?.saml_idp_url}
        />

        <FileField
          error={errors?.saml_x509_certs}
          label="2. X.509 Certificate"
          name="saml_x509_certs"
          onUpload={onFileInput}
          value={connection?.saml_x509_certs?.[0]}
        />
      </Form>
    </Card>
  </Article>
);

export const RipplingUploadAcsUrl: React.FC<Readonly<ConnectionStepProps>> = ({
  connection,
  onNextStep,
}) => (
  <Article>
    <Title className="mb-4">
      Step 3: Upload ACS URL and SP Entity ID to Rippling
    </Title>

    <Text>
      Scrolling down on the SSO Setup Instructions, Rippling will request the
      ACS URL and Service Provider Entity ID.
    </Text>

    <CopyInput
      label="Copy this Single Sign On URL"
      value={connection?.saml_acs_url}
    />

    <Text>
      Input the Single Sign On URL above as both the ACS URL and SP Entity ID.
    </Text>

    <Img
      height={500}
      priority={true}
      src="/sso/rippling/v1/rippling-6.png"
      width={500}
    />

    <Text>Click on the "Move To Next Step" button.</Text>

    <Confirm
      label="I’ve uploaded the SSO URL as the ACS URL and SP Entity ID into Rippling."
      onClick={onNextStep}
    />
  </Article>
);

export const RipplingConfigureAttributeMapping: React.FC<
  Readonly<ConnectionStepProps>
> = ({ connection, onNextStep }) => (
  <Article>
    <Title className="mb-4">Step 4: Configure Attribute Mapping</Title>

    <Text>
      Rippling will now have configuration settings for your preferences
      regarding the application like who should have access and when. Configure
      these settings as needed until you reach the final page letting you know
      the installation has been complete.
    </Text>

    <Img
      height={400}
      priority={true}
      src="/sso/rippling/v1/rippling-7.png"
      width={700}
    />
    <Text>
      Click "Visit the app". The application settings will be presented, here we
      will configure the SAML attribute mapping.
    </Text>

    <Img
      height={400}
      priority={true}
      src="/sso/rippling/v1/rippling-8.png"
      width={700}
    />

    <Text>
      Select the "Settings Tab" then on the left navigation select "SAML
      Attributes" and select the "SAML Response" tab and use the "edit" button
      to add the following key-value pairs. Then, click "Save".
    </Text>

    <ConnectionAttributeMapping connectionType={connection?.type} />

    <Confirm
      label="I've completed the application configuration and SAML attribute mapping."
      onClick={onNextStep}
    />
  </Article>
);
