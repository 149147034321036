import { Text } from '@workos-inc/component-library';
import { ConnectionAttributeMapping } from 'components/attribute-mapping';
import { Card } from 'components/card';
import { Confirm } from 'components/confirm';
import { FileField, TextField } from 'components/fields';
import { Form } from 'components/form';
import { Img } from 'components/image';
import { DownloadInput, ReadOnlyInput } from 'components/input';
import { Article, Title } from 'components/typography';
import { ConnectionStepProps } from 'interfaces/step-props';
import React from 'react';
import { useFeature } from 'utils/feature-flags';
import { ManualMetadataStep } from './saml/manual-metadata-step';

export const AdfsConfigureRelyingPartyTrust: React.FC<
  Readonly<ConnectionStepProps>
> = ({ connection, onNextStep }) => (
  <Article>
    <Title>Step 1: Configure a Relying Party Trust</Title>

    <Text>Open the AD FS Management console.</Text>

    <Img priority height={912} src="/sso/adfs/v1/adfs-1.png" width={1278} />

    <Text>Click “Relying Party Trusts” on the left sidebar.</Text>

    <Img priority height={912} src="/sso/adfs/v1/adfs-2.png" width={1278} />

    <Text>
      Open the “AD FS Relying Party Trust Wizard” by clicking “Add Relying Party
      Trust...” on the right sidebar.
    </Text>

    <Img height={912} src="/sso/adfs/v1/adfs-3.png" width={1278} />

    <Text>Select “Claims aware” and then “Start”.</Text>

    <Text>Download the provided Metadata file from WorkOS.</Text>

    <DownloadInput
      filename="metadata.xml"
      label="Your SP Metadata File"
      value={connection?.saml_sp_metadata_url}
    />

    <Text>
      Select “Import data about the relying party from a file,” then select the
      SP Metadata file you downloaded, then click “Next”.
    </Text>

    <Img height={912} src="/sso/adfs/v1/adfs-4.png" width={1278} />

    <Text>Select “Permit everyone” and then “Next”.</Text>

    <Img height={912} src="/sso/adfs/v1/adfs-5.png" width={1278} />

    <Confirm
      buttonText="Continue to Next Step"
      label="I’ve selected “Permit everyone” and clicked on “Next”."
      onClick={onNextStep}
    />
  </Article>
);

export const AdfsAccessPolicy: React.FC<Readonly<ConnectionStepProps>> = ({
  connection,
  onNextStep,
}) => (
  <Article>
    <Title>Step 2: Choose Access Policy</Title>

    <Text>
      Click the “Endpoints” tab and confirm that the “SAML Assertion Consumer
      Endpoints” matches the URL below and click “Next”.
    </Text>

    <Img priority height={912} src="/sso/adfs/v1/adfs-6.png" width={1278} />

    <ReadOnlyInput
      label="Confirm this URL matches the SAML Assertion Consumer Endpoint"
      value={connection?.saml_acs_url}
    />

    <Text>
      Select “Configure claims issuance policy for this application” and
      “Close”.
    </Text>

    <Img height={912} src="/sso/adfs/v1/adfs-7.png" width={1278} />

    <Confirm
      label="I’ve finished and closed the Relying Party Trust Wizard."
      onClick={onNextStep}
    />
  </Article>
);

export const AdfsClaimsPolicy: React.FC<Readonly<ConnectionStepProps>> = ({
  connection,
  onNextStep,
}) => (
  <Article>
    <Title>Step 3: Configure Claims Issuance Policy</Title>

    <Text>Click “Add Rule” in the “Edit Claims Issuance Policy” window.</Text>

    <Img priority height={912} src="/sso/adfs/v1/adfs-8.png" width={1278} />

    <Text>Select “Send LDAP Attributes as Claims” and then “Next”.</Text>

    <Img height={912} src="/sso/adfs/v1/adfs-9.png" width={1278} />

    <Text>Submit “Attributes” as “Claim rule name:”</Text>

    <Text>Select “Active Directory” as “Attribute Store”</Text>

    <Text>Fill in the following Attribute mappings:</Text>

    <ConnectionAttributeMapping connectionType={connection?.type} />

    <Text>Click “Finish”.</Text>

    <Img height={582} src="/sso/adfs/v1/adfs-10.png" width={716} />

    <Confirm
      label="I’ve finished and closed the claims issuance policy."
      onClick={onNextStep}
    />
  </Article>
);

export const AdfsUploadMetadata: React.FC<Readonly<ConnectionStepProps>> = (
  connectionStepProps,
) => {
  const {
    connection,
    errors,
    isLoading,
    onFileInput,
    onInputChange,
    onNextStep,
    validationErrors,
    selectedConfiguration,
    setSelectedConfiguration,
  } = connectionStepProps;

  const isInputSamlCertificateMetadataUrlEnabled = useFeature(
    'inputSamlCertificateMetadataUrl',
  );

  if (!isInputSamlCertificateMetadataUrlEnabled) {
    return (
      <Article>
        <Title>Step 4: Upload Token Signing Certificate</Title>

        <Text>
          {`Click on Service > Certificates and select the “Token-signing”
          certificate and “View Certificate” in the right side bar.`}
        </Text>

        <Img
          priority
          height={912}
          src="/sso/adfs/v1/adfs-11.png"
          width={1278}
        />

        <Text>
          Click the “Details” tab and then click “Copy to File” in the
          Certificate window.
        </Text>

        <Img height={912} src="/sso/adfs/v1/adfs-12.png" width={1278} />

        <Text>Select “No, do not export the private key” then “Next”.</Text>

        <Img height={912} src="/sso/adfs/v1/adfs-13.png" width={1278} />

        <Text>Select “Base-64 encoded X.509 (.CER)” then “Next”.</Text>

        <Img height={912} src="/sso/adfs/v1/adfs-14.png" width={1278} />

        <Text>
          Specify a file name and finish exporting the public certificate.
        </Text>

        <Img height={912} src="/sso/adfs/v1/adfs-15.png" width={1278} />

        <Text>
          {`Click on Service > Endpoints and find your “SAML 2.0/WS Federation”
          Endpoint.`}
        </Text>

        <Img
          priority
          height={912}
          src="/sso/adfs/v1/adfs-16.png"
          width={1278}
        />

        <Text>
          Provide your SAML 2.0 Endpoint, it will be located at the endpoint
          found; ex: https://adfs.foocorp.com/adfs/ls
        </Text>

        <Card>
          <Form
            disabled={
              !connection?.saml_idp_url ||
              !connection?.saml_x509_certs ||
              validationErrors?.saml_idp_url?.value === connection?.saml_idp_url
            }
            isLoading={isLoading}
            isUpdate={!!(errors?.saml_x509_certs || errors?.saml_idp_url)}
            onSubmit={onNextStep}
          >
            <TextField
              autoFocus={true}
              error={errors?.saml_idp_url || validationErrors?.saml_idp_url}
              label="SAML 2.0 Endpoint"
              name="saml_idp_url"
              onChange={onInputChange}
              placeholder="https://adfs.foocorp.com/adfs/123"
              value={connection?.saml_idp_url}
            />

            <FileField
              error={errors?.saml_x509_certs}
              label="Token Signing Certificate"
              name="saml_x509_certs"
              onUpload={onFileInput}
              value={connection?.saml_x509_certs?.[0]}
            />
          </Form>
        </Card>
      </Article>
    );
  }

  return (
    <Article>
      <Title>Step 4: Upload Metadata URL</Title>

      {selectedConfiguration === 'dynamic' && (
        <>
          <Text>
            Next you will want to obtain the Metadata URL from your ADFS server.
            ADFS publishes its metadata to a standard URL by default:
            (https://SERVER/federationmetadata/2007-06/federationmetadata.xml)
            where "SERVER" is your federation service FQDN. You can also find
            your ADFS Federation Metadata URL through the AD FS Management in
            "AD FS -{'>'} Service -{'>'} Endpoints" and navigate to the Metadata
            section.
          </Text>
          <Img
            priority
            height={1708}
            src="/sso/adfs/v1/adfs-metadata-upload-2.png"
            width={2710}
          />
          <Text>
            Upload the Metadata URL and the connection will be verified and
            linked!
          </Text>
          <Card>
            <Form
              disabled={!connection?.saml_idp_metadata_url}
              isLoading={isLoading}
              isUpdate={!!errors?.saml_idp_metadata_url}
              onSubmit={onNextStep}
              secondaryAction={() => setSelectedConfiguration('manual')}
              secondaryText="Configure Manually"
            >
              <TextField
                error={errors?.saml_idp_metadata_url}
                label="IdP Metadata URL"
                name="saml_idp_metadata_url"
                onChange={onInputChange}
                placeholder="https://SERVER/federationmetadata/2007-06/federationmetadata.xml"
                value={connection?.saml_idp_metadata_url}
              />
            </Form>
          </Card>
        </>
      )}

      {selectedConfiguration === 'manual' && (
        <ManualMetadataStep
          {...connectionStepProps}
          idpEntityIdPlaceholder="https://sso.adfs.com/12345abc..."
          idpSsoUrlPlaceholder="https://adfs.test.com/adfs/ls..."
          onConfigurationTypeChange={setSelectedConfiguration}
        />
      )}
    </Article>
  );
};
