import { Text } from '@workos-inc/component-library';
import { ConnectionAttributeMapping } from 'components/attribute-mapping';
import { Card } from 'components/card';
import { Confirm } from 'components/confirm';
import { FileField, TextField } from 'components/fields';
import { Form } from 'components/form';
import { Img } from 'components/image';
import { CopyInput } from 'components/input';
import { ManualMetadataStep } from 'components/sso/steps/saml/manual-metadata-step';
import { StoreContext } from 'components/store-provider';
import { Article, Ol, Title } from 'components/typography';
import { ConnectionStepProps } from 'interfaces/step-props';
import React, { useContext } from 'react';
import { useFeature } from 'utils/feature-flags';

export const OktaSAMLIntegration: React.FC<
  Readonly<Pick<ConnectionStepProps, 'onNextStep'>>
> = ({ onNextStep }) => {
  const {
    appName: [appName],
    connection: [connection],
  } = useContext(StoreContext);

  return (
    <Article>
      <Title className="mb-4">Step 1: Create SAML Integration</Title>

      <Img
        priority
        height={313}
        src="/sso/okta/v1/okta-new-10.png"
        width={1438}
      />

      <Text>
        Click "Create App Integration" from your Okta Application dashboard.
      </Text>

      <Img priority height={759} src="/sso/okta/v1/okta-2.png" width={1213} />

      <Text>Click “Create New App”.</Text>

      <Img height={590} src="/sso/okta/v1/okta-new-11.png" width={1038} />

      <Text>Select "SAML 2.0" as a Sign on method, then click "Next".</Text>

      <Img height={813} src="/sso/okta/v1/okta-4.png" width={825} />

      <Text>
        Enter an App name that describes {appName}, then click "Next".
      </Text>

      <CopyInput
        label="Copy this Single sign on URL"
        value={connection?.saml_acs_url}
      />

      <Text>
        Submit the Single Sign on URL as the "Single sign on URL" and as the
        "Audience URI".
      </Text>

      <Img height={725} src="/sso/okta/v1/okta-5.png" width={750} />

      <Confirm
        label="I've configured the Single sign on URL and Audience URI."
        onClick={onNextStep}
      />
    </Article>
  );
};

export const OktaAttributeStatements: React.FC<
  Readonly<Pick<ConnectionStepProps, 'onNextStep'>>
> = ({ onNextStep }) => {
  const {
    connection: [connection],
  } = useContext(StoreContext);

  return (
    <Article>
      <Title className="mb-4">Step 2: Configure Attribute Statements</Title>

      <Text>Continue scrolling to the Attribute Statements configuration.</Text>

      <Img priority height={439} src="/sso/okta/v1/okta-6.png" width={720} />

      <Text>Fill in the following Attribute Statements and click "Next":</Text>

      <ConnectionAttributeMapping connectionType={connection?.type} />

      <Confirm
        label="I've finished configuring the Attribute Statements
        and clicked “Next”."
        onClick={onNextStep}
      />
    </Article>
  );
};

export const OktaApplicationFeedback: React.FC<
  Readonly<Pick<ConnectionStepProps, 'onNextStep'>>
> = ({ onNextStep }) => (
  <Article>
    <Title className="mb-4">Step 3: Submit Application Feedback</Title>

    <Text>
      Select "I'm an Okta customer adding an internal app" from the options
      menu.
    </Text>

    <Img priority height={235} src="/sso/okta/v1/okta-7.png" width={733} />

    <Text>Complete the form with any comments and select “Finish”.</Text>

    <Confirm
      label="I’ve completed the feedback formed and clicked “Finish”."
      onClick={onNextStep}
    />
  </Article>
);

export const OktaAddUsers: React.FC<
  Readonly<Pick<ConnectionStepProps, 'onNextStep'>>
> = ({ onNextStep }) => (
  <Article>
    <Title className="mb-4">Step 4: Add Users to SAML App</Title>

    <Text>
      In order to test out SSO authentication, you will first need to assign
      your Okta SAML app to Groups.
    </Text>

    <Img priority height={389} src="/sso/okta/v1/okta-8.png" width={743} />

    <Text>
      Click on the "Assignments" tab of the app and then select "Assign to
      Groups".
    </Text>

    <Img height={590} src="/sso/okta/v1/okta-9.png" width={608} />

    <Text>
      Find the Group(s) that you would like to assign to the app and click
      "Assign" next to it. Click "Done" when you're finished.
    </Text>

    <Confirm
      label="I’ve completed the feedback formed and clicked “Finish”."
      onClick={onNextStep}
    />
  </Article>
);

export const OktaIdentiyProviderMetadata: React.FC<
  Readonly<ConnectionStepProps>
> = (connectionStepProps) => {
  const {
    connection,
    isLoading,
    errors,
    onNextStep,
    onInputChange,
    onFileInput,
    validationErrors,
    selectedConfiguration,
    setSelectedConfiguration,
  } = connectionStepProps;

  const isInputSamlCertificateMetadataUrlEnabled = useFeature(
    'inputSamlCertificateMetadataUrl',
  );

  if (!isInputSamlCertificateMetadataUrlEnabled) {
    return (
      <Article>
        <Title className="mb-4">
          Step 5: Upload Identity Provider Metadata
        </Title>

        <Text>
          Click on the "Sign On" tab of the SAML app and select "View Setup
          Instructions".
        </Text>

        <Img priority height={694} src="/sso/okta/v1/okta-10.png" width={828} />

        <Text>
          A separate tab will open with your Identity Provider metadata.
        </Text>

        <Img height={702} src="/sso/okta/v2/okta-11.png" width={635} />

        <Card>
          <Form
            disabled={
              !connection?.saml_idp_url ||
              !connection?.saml_x509_certs ||
              !connection?.saml_entity_id ||
              validationErrors?.saml_idp_url?.value === connection?.saml_idp_url
            }
            isLoading={isLoading}
            isUpdate={
              !!(
                errors?.saml_idp_url ||
                errors?.saml_entity_id ||
                errors?.saml_x509_certs
              )
            }
            onSubmit={onNextStep}
          >
            <TextField
              autoFocus={true}
              error={errors?.saml_idp_url || validationErrors?.saml_idp_url}
              label="1. Identity Provider Single Sign-On URL"
              name="saml_idp_url"
              onChange={onInputChange}
              placeholder="https://foo-corp.okta.com/app/exkgq2/sso/saml"
              value={connection?.saml_idp_url}
            />

            <FileField
              error={errors?.saml_x509_certs}
              label="2. X.509 Certificate"
              name="saml_x509_certs"
              onUpload={onFileInput}
              value={connection?.saml_x509_certs?.[0]}
            />
          </Form>
        </Card>
      </Article>
    );
  }

  return (
    <Article>
      <Title className="mb-4">Step 5: Upload Identity Provider Metadata</Title>

      <Text>
        The final step for implementing SAML SSO requires sharing your identity
        provider's metadata with the application.
      </Text>

      {selectedConfiguration === 'dynamic' && (
        <>
          <Text as="h2" size="large" weight="medium">
            URL Metadata Configuration
          </Text>

          <Ol className="my-4">
            <li>
              Click on the “Sign On” tab of the SAML app you just created.
            </li>
            <li>
              Click the “Identity Provider metadata” link below the View Setup
              Instructions button.
            </li>
            <li>A separate tab will open. Copy the link and paste it below.</li>
          </Ol>

          <Img
            height={1066}
            src="/sso/okta/v1/saml-metadata-url.png"
            width={2560}
          />

          <Card>
            <Form
              disabled={!connection?.saml_idp_metadata_url}
              isLoading={isLoading}
              isUpdate={!!errors?.saml_idp_metadata_url}
              onSubmit={onNextStep}
              secondaryAction={() => setSelectedConfiguration('manual')}
              secondaryText="Configure Manually"
            >
              <TextField
                error={errors?.saml_idp_metadata_url}
                label="IdP Metadata URL"
                name="saml_idp_metadata_url"
                onChange={onInputChange}
                placeholder="https://"
                value={connection?.saml_idp_metadata_url}
              />
            </Form>
          </Card>
        </>
      )}

      {selectedConfiguration === 'manual' && (
        <ManualMetadataStep
          {...connectionStepProps}
          idpEntityIdPlaceholder="http://www.okta.com/exkgq2c19CUpt2Brr46"
          idpSsoUrlPlaceholder="http://www.okta.com/exkgq2c19CUpt2Brr46"
          onConfigurationTypeChange={setSelectedConfiguration}
        />
      )}
    </Article>
  );
};
